'use client';
import { PageWrapper } from '@/components/layout/wrapper/public';
import { useEffect } from 'react';
import { redirect } from 'next/navigation';

const IndexPage = () => {
    useEffect(() => {
        redirect('/home');
        /* const authCookie = GetAuthCookie();
        if (authCookie) {
            redirect(Constants.dashboardUrl);
        } else {
            redirect(Constants.loginUrl);
        } */
    }, []);

    return (
        <PageWrapper>
            <></>
        </PageWrapper>
    );
};

export default IndexPage;

'use client'
import { useAppSelector } from "@/store/redux/hooks";
import { useToast } from "@/hooks/useToast";
import { useEffect } from "react";
import  './style.scss';

interface PropsInterface {
    children: React.ReactNode;
}

export const PageWrapper = ({ children }: PropsInterface) => {
    const { ConfigToast, setConfigToast, ToastElement, toastManagerRef } =
        useToast();
    const { show, toastData } = useAppSelector(state => state.toast);

    useEffect(() => {
        if (show) {
            Alerts(toastData.message, toastData.color);
        }
    }, [show]);

    const Alerts = (message:string, color:any) => {
        ConfigToast.text = message;
        ConfigToast.backColor = color;
        setConfigToast(ConfigToast);
    };

    return (
        <main>
            <ToastElement ref={toastManagerRef} />
            <div className="root">
                {children}
            </div>
        </main>
    );
};
